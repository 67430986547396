<template lang='pug'>
v-container
    v-row
        v-col.d-flex
            v-combobox.pr-2(label='Part Search', color='brand', dense, outlined, :loading='searchingParts', v-model='partSearchText', :items='searchResults', @keyup='handlePartInput', :search-input.sync='selectedPart.product_id', light, item-value='product_id', item-text='product_id')
                template(v-slot:item='data')
                    v-list-item(@click='selectPart(data.item)')
                        v-list-item-content
                            v-list-item-title
                                span.mr-1.font-weight-bold {{data.item.product_id}}
                            v-list-item-subtitle
                                span.white--text(v-if="data.item.part_no !== ''") {{`${data.item.part_no}: `}}
                                span {{ data.item.custom_description || data.item.description }}
                    v-tooltip(right)
                        template(v-slot:activator="{on, attrs}")
                            v-icon(v-bind="attrs", v-on="on") info
                        div
                            p Product ID: {{data.item.product_id}}
                            p Part Number: {{data.item.part_no}}
                            p Manufacturer: {{data.item.mfr}}
                            P Description: {{data.item.custom_description || data.item.description}}
            v-text-field.pr-2(type='number', v-model.number='quantity', dense, outlined, color='brand', label='Quantity')
            v-btn.white--text(color='brand', @click='addPart(false)', :loading='addingPart') Add
    v-row
        v-col
            v-expansion-panels(flat)
                v-expansion-panel
                    v-expansion-panel-header  Add Custom Part
                    v-expansion-panel-content
                        v-form(ref='newPartForm')
                            v-row
                                v-col.d-flex
                                    v-text-field.pr-2(label='Part Number', outlined, dense, color='brand', v-model='newCustomPart.part_no')
                                    v-combobox(v-model='mfrSearchText', outlined, dense, color='brand', label='Manufacturer', :items='manufacturerResults', @keyup='handleMfrInput', :loading='searchingManufacturers', :search-input.sync='newCustomPart.mfr', item-text='name')
                            v-row
                                v-col.d-flex
                                    v-text-field.pr-2(v-model='newCustomPart.description', outlined, dense, color='brand', label='Description')
                                    v-text-field.pr-2(v-model.number='newCustomPart.cost', outlined, dense, color='brand', label='Cost', prefix='$')
                                    v-text-field(v-model.number='newCustomPart.quantity', outlined, dense, color='brand', label='Quantity', type='number') Quantity
                            v-row
                                v-col.flex-shrink-0.flex-grow-1
                                v-col.flex-shrink-1.flex-grow-0
                                    v-btn.white--text(color='brand', small, @click='addPart(true)', :disabled='!canAddNewPart', :loading='addingPart') Add Custom Part
</template>
<script>
import {debounce} from 'lodash';
export default {
    props: {
        mmlId: {
            type: String,
            default () {
                return '';
            }
        }
    },
    data () {
        return {
            accessKey: 'satmmls',
            partSearchText: '',
            mfrSearchText: '',
            searchResults: [],
            manufacturerResults: [],
            searchingParts: false,
            searchingManufacturers: false,
            selectedPart: {},
            quantity: 1,
            addingPart: false,
            newCustomPart: {
                description: '',
                quantity: 1,
                cost: 0,
                mfr: '',
                part_no: ''
            }
        };
    },
    methods: {
        /**
         * Searching for parts based on the input text.
         *
         * @param {String} searchString
         * @returns {void}
         */
        searchParts: debounce(async function (searchString) {
            const terms = searchString.toLowerCase().split(' ');
            const searchResults = await this.sendCommand({
                action: 'searchParts',
                parameters: {
                    terms
                }
            });
            this.searchResults = searchResults.parts;
            this.searchingParts = false;
        }, 500),
        /**
         * Searching for manufacturers based on the input text.
         *
         * @param {String} searchString
         * @returns {void}
         */
        searchManufacturer: debounce(async function (searchString) {
            const terms = searchString.toLowerCase().split(' ');
            const searchResults = await this.sendCommand({
                action: 'searchManufacturers',
                parameters: {
                    terms
                }
            });
            this.manufacturerResults = searchResults;
            this.searchingManufacturers = false;

        }, 500),
        /**
         * Click event handler for part being clicked from the drop-down list (serch results)
         *
         * @param {Object} partData
         */
        selectPart (partData) {
            this.selectedPart = partData;
        },
        /**
         * keyup event handler for part search input
         */
        handlePartInput (event) {
            this.searchingParts = true;
            this.searchParts(event.target.value);
        },
        /**
         * keyup event handler for manufacturer input
         */
        handleMfrInput (event) {
            this.searchingManufacturers = true;
            this.searchManufacturer(event.target.value);
        },
        /**
         * Adds a part to the server. The part may be from Jonas or it may be custom.
         *
         * @param {Boolean} custom - part being added is to be custom
         */
        async addPart (custom = false) {
            this.addingPart = true;
            const parameters = {
                custom,
                mmlId: this.mmlId,
                partData: {
                    quantity: custom ? this.newCustomPart.quantity : this.quantity
                }
            };
            if (custom) {
                parameters.partData.part_no = this.newCustomPart.part_no;
                parameters.partData.mfr = this.newCustomPart.mfr;
                parameters.partData.description = this.newCustomPart.description;
                parameters.partData.cost = this.newCustomPart.cost;
            } else {
                parameters.partData.product_id = this.selectedPart.product_id;
            }
            await this.$store.dispatch('mmls/addPart', parameters);
            if (this.$refs.newPartForm) {
                this.$refs.newPartForm.reset();
                this.$refs.newPartForm.quantity = 1; // resetting quantity since this is set to null when resetting the form.
            }
            await this.$emit('partAdded');
            this.addingPart = false;
        }
    },
    computed: {
        /**
         * Returns `true` if the form is completely filled out (valid)
         */
        canAddNewPart () {
            return this.newCustomPart.part_no && this.newCustomPart.mfr && this.newCustomPart.quantity > 0;
        }
    },
    mounted () {
        window.partselectorvm = this;
    }
};
</script>
