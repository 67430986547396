<template lang="pug">
v-container
    v-row
        v-col.flex-grow-1
        v-col.flex-shrink-1.flex-grow-0.d-flex
            v-avatar.white--text.ml-1(v-for='connection in connections', color='brand', size='36', :title='connection.name', :key='connection.name')
                span {{ getAvatarText(connection) }}

    v-progress-linear(indeterminate, absolute, v-if='saving || loading', color='brand')
    v-form(ref='mmlData', :disabled='loading')
        v-row.mt-2
            v-col
                v-text-field(label='Name', color='brand', dense, outlined, v-model='mmlData.name', @change='saveMML', :loading='loading')
            v-col
                v-text-field(label='Job Number', color='brand', dense, outlined, v-model='mmlData.jobNumber', @change='saveMML', :loading='loading')
        v-row.mt-2(v-if='mmlData.bid')
            v-col
                v-text-field(label='Bid', disabled, dense, outlined, color='brand', v-model='mmlData.bidData.name')
            v-col
                v-text-field(label='Deal', disabled, dense, outlined, color='brand', v-model='mmlData.deal.name')
    .text-h5 Attachments
    v-row
        v-col
            attachmentseditor(:itemId='id', tableName='mmls')
    v-row
        v-col
            v-card.mb-12(light)
                v-card-title.brand.white--text.py-2
                    span Parts
                    v-spacer
                    v-menu(:close-on-content-click='false', transition='scale-transition', offset-y, v-model='partSelectorDialog')
                        template(v-slot:activator='{on, attrs}')
                            v-btn.white--text(v-on='on', color='blue', small, :disabled='loading') Add Part
                                v-icon add
                        v-card(light)
                            v-card-title Select Part
                            v-card-text
                                PartSelector(@partAdded='partAdded', :mmlId='id')
                v-card-text
                    v-data-table.mmlTable(:headers='visibleHeaders', :items='allParts', :loading='loading', dense, ref='table', :items-per-page='-1', :footer-props='{"items-per-page-options":[10, 25,-1]}', no-data-text='No parts added', :custom-sort='customSort')
                        template(v-slot:item.qty='{item, value}')
                            noteseditor(:partId='item.id', property='qty', :isCustomPart='Boolean(item.part_no)', @saving='saving = true', @doneSaving='saving = false')
                                template(v-slot:default)
                                    v-text-field.narrowInput(v-model.number='item.qty', type='number', dense, outlined, hide-details, @change='partEdited(item)', @input='partEdited(item)')
                        template(v-slot:item.product_id='{item, value}')
                            noteseditor(:partId='item.id', property='product_id', :isCustomPart='Boolean(item.part_no)', @saving='saving = true', @doneSaving='saving = false')
                                template(v-slot:default)
                                    copyabletext(:text='item.part ? item.part.product_id : "N/A"')
                        template(v-slot:item.part_no='{item, value}')
                            noteseditor(:partId='item.id', property='part_no', :isCustomPart='Boolean(item.part_no)', @saving='saving = true', @doneSaving='saving = false')
                                template(v-slot:default)
                                    copyabletext(:text='item.part ? item.part.part_no : item.part_no', :lineclamp='2', :title='item.part ? item.part.part_no : item.part_no')
                        template(v-slot:item.tracking_code='{item, value}')
                            noteseditor(:partId='item.id', property='tracking_code', :isCustomPart='Boolean(item.part_no)', @saving='saving = true', @doneSaving='saving = false')
                                template(v-slot:default)
                                    clicktoedit(v-model='item.custom_tracking_code', @change='partEdited(item)', :text='getTrackingCodeText(item)')
                                        template(v-slot:append)
                                            v-badge(v-if='item.custom_tracking_code', color='orange', dot, inline)
                                        template(v-slot:title)
                                            span Edit Tracking Code
                        template(v-slot:item.description='{item, value}')
                            noteseditor(:partId='item.id', property='description', :isCustomPart='Boolean(item.part_no)', @saving='saving = true', @doneSaving='saving = false')
                                template(v-slot:default)
                                    copyabletext(:text='item.part? (item.part.custom_description || item.part.description) : item.description', :lineclamp='2', :title='item.part? (item.part.custom_description || item.part.description) : item.description')
                        template(v-slot:item.selling_price='{item, value}')
                            noteseditor(:partId='item.id', property='selling_price', :isCustomPart='Boolean(item.part_no)', @saving='saving = true', @doneSaving='saving = false')
                                template(v-slot:default)
                                    span {{ item.selling_price }}
                        template(v-slot:item.cost='{item, value}')
                            noteseditor(:partId='item.id', property='cost', :isCustomPart='Boolean(item.part_no)', @saving='saving = true', @doneSaving='saving = false')
                                template(v-slot:default)
                                    template(v-if='item.part')
                                        span {{ dti.formatCurrency(item.part.cost) }}
                                    template(v-else)
                                        clicktoedit(v-model='item.cost', @change='partEdited(item)', isCurrency)
                                            template(v-slot:title)
                                                span Edit Custom Part Cost
                        template(v-slot:item.req_on_site='{item, value}')
                            noteseditor(:partId='item.id', property='req_on_site', :isCustomPart='Boolean(item.part_no)', @saving='saving = true', @doneSaving='saving = false')
                                template(v-slot:default)
                                    datepicker(v-model='item.req_on_site', label='', @datechanged='partEdited(item)')
                        template(v-slot:item.link_to_quote='{item, value}')
                            noteseditor(:partId='item.id', property='link_to_quote', :isCustomPart='Boolean(item.part_no)', @saving='saving = true', @doneSaving='saving = false')
                                template(v-slot:default)
                                    clicktoedit(v-model='item.link_to_quote', @change='partEdited(item)', :lineclamp='2')
                                        template(v-slot:title)
                                            span Edit Link to Quote
                        template(v-slot:item.due_date='{item, value}')
                            noteseditor(:partId='item.id', property='due_date', :isCustomPart='Boolean(item.part_no)', @saving='saving = true', @doneSaving='saving = false')
                                template(v-slot:default)
                                    datepicker(v-model='item.due_date', label='', @datechanged='partEdited(item)')
                        template(v-slot:item.received_date='{item, value}')
                            noteseditor(:partId='item.id', property='received_date', :isCustomPart='Boolean(item.part_no)', @saving='saving = true', @doneSaving='saving = false')
                                template(v-slot:default)
                                    datepicker(v-model='item.received_date', label='', @datechanged='partEdited(item)')
                        template(v-slot:item.ship_date='{item, value}')
                            noteseditor(:partId='item.id', property='ship_date', :isCustomPart='Boolean(item.part_no)', @saving='saving = true', @doneSaving='saving = false')
                                template(v-slot:default)
                                    datepicker(v-model='item.ship_date', label='', @datechanged='partEdited(item)')
                        template(v-slot:item.notes='{item, value}')
                            notesmanager(:part='item', :properties='noteProperties', @saving='saving = true', @doneSaving='saving = false')
                        template(v-slot:item.delete='{item, value}')
                            v-btn(icon, color='red', @click='removePart(item)')
                                v-icon delete
                        template(v-slot:footer.prepend)
                            v-menu(:close-on-content-click='false', transition='scale-transition', offset-y, v-model='filtersMenu')
                                template(v-slot:activator='{ on, attrs }', color='brand')
                                    v-btn(icon, v-on='on', color='brand', title='Table Headers')
                                        v-icon tune
                                v-card(light)
                                    v-card-title Table headers
                                        v-card-text
                                            v-list
                                                v-list-item(v-for='header in headers', :key='header.value')
                                                    v-list-item-title {{header.text}}
                                                    v-list-item-action
                                                        v-switch(v-model='header.visible', color='brand')
                            v-btn(text, icon, color='brand', title='View cost by Tracking Code', @click='viewCostByTrackCode')
                                v-icon toc
                            v-btn(text, icon, color='brand', @click='exportCSV', title='Export as CSV')
                                v-icon description
                            v-btn(text, icon, color='brand', @click='exportJonas', title='Export Jonas MML')
                                v-icon request_quote
    v-dialog(v-model='trackingCodeSummaryDialog', max-width='500')
        v-card(light)
            v-card-title Cost by Tracking Code
            v-card-text
                v-simple-table
                    template(v-slot:default)
                        thead
                            tr
                                th Tracking Code
                                th Cost
                        tbody
                            tr(v-for='row in trackCodeSummaryData', :key='row.tracking_code')
                                td
                                    copyabletext(:text='row.tracking_code')
                                td
                                    copyabletext(:text='dti.formatCurrency(row.cost)', :copiedText='row.cost')
            v-card-actions
                v-spacer
                v-btn(text, color='brand', @click='trackingCodeSummaryDialog = false') Close

    v-snackbar(v-model='showAlert', dark, :timeout='3000', dense) {{ message }}

</template>

<script>
import {debounce} from 'lodash';

import PartSelector from '@/components/common/partselector';
import datepicker from '@/components/common/datepicker';
import noteseditor from '@/components/common/noteseditor';
import attachmentseditor from '@/components/common/attachmentseditor';
import clicktoedit from '@/components/common/clicktoedit';
import notesmanager from '@/components/common/notesmanager';
import copyabletext from '@/components/common/copyabletext';

export default {
    components: {
        PartSelector,
        datepicker,
        noteseditor,
        attachmentseditor,
        clicktoedit,
        notesmanager,
        copyabletext
    },
    props: {
        id: {
            type: String,
            default () {
                return '';
            }
        }
    },
    data () {
        return {
            dti,
            accessKey: 'satmmls',
            message: '',
            busy: false,

            loading: true,

            editedPartIds: [],
            editedCustomPartIds: [],

            newPart: null,
            partSelectorDialog: false,
            filtersMenu: false,
            headers: [
                {
                    text: 'Product ID',
                    value: 'product_id',
                    align: 'left',
                    sortable: true,
                    visible: true,
                    width: '50px'
                },
                {
                    text: 'Part #',
                    value: 'part_no',
                    align: 'left',
                    sortable: true,
                    visible: true,
                    width: 150
                },
                {
                    text: 'Track Code',
                    value: 'tracking_code',
                    align: 'left',
                    sortable: true,
                    visible: true,
                    width: 100
                },
                {
                    text: 'Description',
                    value: 'description',
                    align: 'left',
                    sortable: true,
                    visible: true,
                    width: 200
                },
                {
                    text: 'Quantity',
                    value: 'qty',
                    align: 'left',
                    sortable: true,
                    visible: true
                },
                {
                    text: 'Current Cost',
                    value: 'cost',
                    align: 'left',
                    sortable: true,
                    visible: true
                },
                {
                    text: 'Link to Quote',
                    value: 'link_to_quote',
                    align: 'left',
                    sortable: false,
                    visible: true,
                    width: 75
                },
                {
                    text: 'Required on Site',
                    value: 'req_on_site',
                    align: 'left',
                    sortable: true,
                    visible: true
                },
                {
                    text: 'Due Date',
                    value: 'due_date',
                    align: 'left',
                    sortable: true,
                    visible: true
                },
                {
                    text: 'Received Date',
                    value: 'received_date',
                    align: 'left',
                    sortable: true,
                    visible: true
                },
                {
                    text: 'Ship Date',
                    value: 'ship_date',
                    align: 'left',
                    sortable: true,
                    visible: true
                },
                {
                    text: 'Notes',
                    value: 'notes',
                    align: 'center',
                    sortable: false,
                    visible: true,
                    excludeFromExport: true
                },
                {
                    text: 'Delete',
                    value: 'delete',
                    align: 'center',
                    sortable: false,
                    visible: true,
                    excludeFromExport: true
                }
            ],

            saving: false,
            newNoteProperty: null,
            newNoteText: '',
            newNoteMenu: false,
            trackCodeSummaryData: [],
            trackingCodeSummaryDialog: false
        };
    },
    methods: {
        /**
         * Fetches all MML data from the server
         */
        async getMMLData () {
            this.loading = true;
            const mml = await this.$store.dispatch('mmls/getMMLData', this.id);
            if (!mml) {
                this.message = 'Error getting MML data';
            }
            this.loading = false;
            await this.$store.dispatch('mmls/getNotes', this.allParts.map(part => part.id));
        },
        /**
         * `partAdded` event handler from child component (`partselector.vue`)
         * @param {Object[]} parts - Array of part objects
         * @param {Object[]} customParts - Array of custom part objects
         */
        partAdded () {
            this.partSelectorDialog = false;
        },
        /**
         * Removes a part from the MML. If the part is a part group, _all_ parts in that group are removed.
         * @param {Object} partObject - The item to be deleted
         * @param {Boolean} custom - Item is custom part.
         */
        async removePart (partObject) {
            const custom = Boolean(partObject.part_no);
            let ids = [partObject.id];
            this.saving = true;
            await this.$store.dispatch('mmls/removePart', {
                mmlPartIds: ids,
                mmlId: this.id,
                custom
            });
            this.saving = false;
            // todo: remove commented code. make sure parts are removed from the store.
            // this.mmlData.mml_parts = allParts.parts;
            // this.mmlData.mml_custom_parts = allParts.customParts;
        },
        /**
         * Called when a part has been edited. The part ids will be queued up for saving.
         * @param {Object} partObject
         * @param {Boolean} custom
         */
        partEdited (partObject) {
            const targetArray = partObject.part_no ? 'editedCustomPartIds' : 'editedPartIds';
            if (!this[targetArray].includes(partObject.id)) {
                this[targetArray].push(partObject.id);
            }
            this.saveParts();
        },
        /**
         * Saves all queued parts
         */
        saveParts: debounce(async function () {
            this.saving = true;
            console.log('Saving parts');
            await this.$store.dispatch('mmls/saveParts', {
                partIds: this.editedPartIds,
                customPartIds: this.editedCustomPartIds
            });
            this.editedPartIds = [];
            this.editedCustomPartIds = [];
            this.saving = false;
        }, 500),
        /**
         * Saves the MML being edited
         */
        saveMML: debounce(async function () {
            this.saving = true;
            await this.$store.dispatch('mmls/updateMML', {
                action: 'updateMML',
                parameters: this.mmlData
            });
            this.saving = false;
        }, 500),
        /**
         * Returns the header width of the _main_ table at the specified index.
         *
         * @param {Number} headerIndex
         * @returns {Number} width of header
         */
        getHeaderWidth (headerIndex) {
            const headerRow = document.querySelector('.v-data-table thead tr');
            const targetHeader = headerRow.children[headerIndex];
            return targetHeader.offsetWidth;
        },
        hasNote (partId, property) {
            return Boolean(this.$store.getters['mmls/note'](partId, property));
        },
        customSort (items, index, isDesc) {
            /**
             * Gets the value of the object depending of if the part is custom or not.
             *
             * @param {Object} item
             * @param {String} property
             *
             * @returns {String}
             */
            const getValue = (item, property) => {
                const isCustom = item.hasOwnProperty('part_no'); // only custom parts have this property on the part object itself.
                const commonProperties = ['qty', 'req_on_site', 'due_date', 'received_date', 'ship_date'];
                if (commonProperties.includes(property)) {
                    if (typeof item[property] === 'number') {
                        return item[property];
                    } else {
                        return item[property] ? item[property].toString() : '';
                    }
                } else {
                    switch (property) {
                    case 'cost':
                        return isCustom ? item.cost : item.part.cost;
                    case 'qty':
                        return item.qty;
                    case 'product_id':
                        return isCustom ? '' : item.part.product_id;
                    case 'part_no':
                    case 'description':
                        return isCustom ? item[property] : item.part[property];
                    case 'selling_price':
                        return '';
                    case 'tracking_code':
                        return isCustom ? '' : item.part.tracking_code;
                    default:
                        return item.part ? item.part[property] : item[property];
                    }
                }
            };
            if (index.length) {
                const sortBy = index[0];
                items.sort((a, b) => {
                    const aValue = getValue(a, sortBy);
                    const bValue = getValue(b, sortBy);
                    if (aValue.localeCompare) {
                        return aValue.localeCompare(bValue);
                    } else {
                        return aValue - bValue;
                    }
                });

            }
            if (isDesc.length && isDesc[0]) {
                items = items.reverse();
            }
            return items;
        },
        getAvatarText (connection) {
            const [firstName, lastName] = connection.name.split(' ');
            return `${firstName[0]}${lastName[0]}`;
        },
        getTrackingCodeText (partData) {
            if (partData.custom_tracking_code) {
                return partData.custom_tracking_code;
            }
            if (partData.part) {
                return partData.part.tracking_code;
            }
            return 'N/A';
        },
        /**
         * Exports a CSV file of the MML data.
         */
        exportCSV () {
            const exportHeaders = this.headers.filter(header => !header.excludeFromExport);
            const allData = [exportHeaders.map(header => header.text)];
            for (const part of this.allParts) {
                const rowData = [];
                for (const header of exportHeaders) {
                    let value;
                    switch (header.value) {
                    case 'tracking_code':
                        value = this.getTrackingCodeText(part);
                        break;
                    case 'description':
                        value = part.part? (part.part.custom_description || part.part.description) : part.description;
                        break;
                    default:
                        if (part.part) {
                            value = part.part[header.value];
                        }
                        if (!value) {
                            value = part[header.value];
                        }
                    }
                    // enclose the value with quotes to avoid delimiter issues
                    value = value ? `"${value}"` : '';
                    rowData.push(value);
                }
                allData.push(rowData);
            }
            const csvData = `data:text/csv;charset=utf-8,${allData.map(e => e.join(",")).join("\n")}`;
            const fileName = this.mmlData.name;
            let encodedData = encodeURI(csvData);
            encodedData = encodedData.replace(/#/g, '%23');
            const downloadLink = document.createElement('a');
            downloadLink.href = encodedData;
            downloadLink.download = fileName;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        },
        /**
         * Exports a csv with part data to be uploaded to Jonas
         */
        exportJonas () {
            //Index:     0           1         2       3        4           5             6           7      8
            //Column:    A           C         D       E        F           G             H           I      J
            //Value: Tracking Code, Desc, Labor Hrs, Labor $, part $/p, subcontract $, equipment $, other $, qty

            const partsData = this.allParts;
            const csvRows = [];
            partsData.forEach(data => {
                const row = [0,0,0,0,0,0,0,0,0];
                const partCost = data.part ? data.part.cost : data.cost;
                const qtyCost = (data.qty * partCost);

                row[0] = this.sanitizeInput(this.getTrackingCodeText(data), 'number');
                row[1] = this.sanitizeInput(data.part ? data.part.description : data.description, 'string');
                row[4] = this.sanitizeInput(qtyCost, 'number');
                row[8] = this.sanitizeInput(data.qty, 'number');

                csvRows.push(row);
            });

            // Sort rows by tracking code
            csvRows.sort(function (a, b) {
                var A = a[0];
                var B = b[0];
                return (A < B) ? -1 : (A > B) ? 1 : 0;
            });

            const csvData = `data:text/csv;charset=utf-8,${csvRows.map(e => e.join(",")).join("\n")}`;
            const fileName = `${this.mmlData.name} - Jonas MML`;
            let encodedData = encodeURI(csvData);
            encodedData = encodedData.replace(/#/g, '%23');
            const downloadLink = document.createElement('a');
            downloadLink.href = encodedData;
            downloadLink.download = fileName;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        },
        sanitizeInput (value, type = 'string') {
            const ogValue = value;
            const defaultReturn = 0;
            const maxStringLength = 50;

            const numFormat = new Intl.NumberFormat('en-US', {
                style: "decimal",
                maximumFractionDigits: 2
            });

            if(!value || !type) {
                return defaultReturn;
            }

            switch(type.toLowerCase()) {
            case 'string':
                // Not Empty
                value = (value.trim().length > 0) ? value : defaultReturn;
                // Not >50 char
                value = (value.length > maxStringLength) ? value.substring(0, maxStringLength) : value;
                // No Commas
                value = value.replace(/,/g, ';');
                break;
            case 'number':
                //2 decimal places
                value = numFormat.format(value);
                //No Commas
                value = value.replace(/,/g, '');
                //Is a number
                value = (parseFloat(value)) ? parseFloat(value) : defaultReturn;
                break;
            }

            return value;
        },

        viewCostByTrackCode () {
            this.trackCodeSummaryData = [];
            const data = {};
            for (const part of this.allParts) {
                const trackingCode = this.getTrackingCodeText(part);
                const cost = part.part ? part.part.cost : part.cost;
                if (data[trackingCode]) {
                    data[trackingCode].cost += +(part.qty * cost).toFixed(2);
                } else {
                    data[trackingCode] = {
                        cost: +(part.qty * cost).toFixed(2)
                    };
                }
            }
            for (const trackCode of Object.keys(data)) {
                this.trackCodeSummaryData.push({
                    tracking_code: trackCode,
                    cost: +(data[trackCode].cost).toFixed(2)
                });
            }
            this.trackingCodeSummaryDialog = true;
        },
        reset () {
            this.$refs.mmlData.reset();
            this.$store.commit('mmls/reset');
        }
    },
    computed: {
        showAlert: {
            get () {
                return this.message !== '';
            },
            set (val) {
                if (val === false) {
                    this.message = '';
                }
            }
        },
        mmlData () {
            return this.$store.getters['mmls/mmlData'];
        },
        visibleHeaders () {
            return this.headers.filter(header => header.visible);
        },
        noteProperties () {
            return this.headers.filter(header => !['delete', 'notes'].includes(header.value));
        },
        parts () {
            return this.$store.getters['mmls/parts'];
        },
        customParts () {
            return this.$store.getters['mmls/customParts'];
        },
        allParts () {
            return [...this.parts, ...this.customParts];
        },
        connections () {
            return this.$store.getters['mmls/connections'](this.id);
        },
        editHistory () {
            return this.$store.getters['mmls/history'];
        }
    },
    watch: {
        id: {
            immediate: true,
            /**
             * Reset the MML data when the target MML changes.
             */
            handler (newId) {
                if (newId) {
                    this.$store.dispatch('mmls/joinRoom', newId);
                    if (this.$refs.mmlData) {
                        this.$refs.mmlData.reset();
                    }
                    this.getMMLData();
                }
            }
        },
        editHistory () {
            const newItem = this.editHistory[this.editHistory.length - 1];
            if (newItem) {
                const name = newItem.user.name;
                let action = '';
                if (newItem.user.email !== this.$store.state.dorsettUser.email) {
                    let partIdentifier = '';
                    switch (newItem.action) {
                    case 'updateParts':
                        if (newItem.parameters.mmlParts.length) {
                            partIdentifier = newItem.parameters.mmlParts[0].part.product_id;
                        } else {
                            partIdentifier = newItem.parameters.mmlCustomParts[0].part_no;
                        }
                        action = `updated the part: ${partIdentifier}.`;
                        break;
                    case 'updateMML':
                        action = 'updated the MML info.';
                        break;
                    case 'addPart':
                        action = 'added a part.';
                        break;
                    case 'removePart':
                        action = 'removed a part.';
                        break;
                    case 'addNote':
                        action = 'added a note.';
                        break;
                    case 'attachmentsUpdated':
                        action = 'updated attachments';
                        break;
                    case 'removeNote':
                        'removed a note.';
                        break;
                    }
                    this.message = `${name} ${action}`;
                }
            }
        }
    },
    mounted () {
        window.mmleditvm = this;
    }
};
</script>

<style lang="scss" scoped>
.narrowInput {
    width: 80px;
    max-width: 80px;
}
</style>

<style lang="scss">
table {
    tr {

        th,
        td {
            padding-left: 0px !important;
            padding-right: 6px !important;
        }
    }
}
</style>
