<template lang="pug">
.copyableText
    v-hover(v-slot='{hover}')
        .text.d-flex.align-center
            span(:class='{lineclamp: lineclamp}', :style='lineclamp ? `-webkit-line-clamp: ${lineclamp}` : ``') {{ text }}
            v-btn(icon, x-small, @click='copyText')
                v-icon(v-if='hover') content_copy

    v-snackbar(v-model='showAlert', dark, :timeout='3000') {{ message }}
</template>
<script>
export default {
    name: 'copyabletext',
    props: {
        text: {
            type: String,
            default: () => {
                return '';
            }
        },
        copiedText: {
            type: undefined,
            default: () => {
                return '';
            }
        },
        lineclamp: {
            type: Number,
            default: () => {
                return 0;
            }
        }
    },
    data () {
        return {
            message: ''
        };
    },
    methods: {
        copyText () {
            let text = this.text;
            if (typeof this.copiedText === 'number') {
                text = this.copiedText;
            } else {
                text = this.copiedText ? this.copiedText : this.text;
            }
            navigator.clipboard.writeText(text);
            this.message = 'Copied to clipboard';
        }
    },
    computed: {
        showAlert: {
            get () {
                return this.message !== '';
            },
            set (val) {
                if (val === false) {
                    this.message = '';
                }
            },
        },
    },
    watch: {
        message () {

        }
    }
};
</script>
<style lang="scss">
.lineclamp {
    display: -webkit-box;
    -webkit-box-orient: vertical;  
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
