<template lang="pug">
.clickToEdit
    v-menu(:close-on-content-click='false', light)
        template(v-slot:activator='{on}')
            .displayBox
                span.text.black--text(v-on='on', :class='{lineclamp: lineclamp}', :style='lineclamp ? `-webkit-line-clamp: ${lineclamp}` : ``') {{ isCurrency ? dti.formatCurrency(value) : value || text || '...' }}
                    slot(name='append')
        v-card
            v-card-title
                slot(name='title')
            v-card-text
                template(v-if='isCurrency')
                    v-text-field(:value='value', @input='emitChange', color='brand', outlined, dense, prefix='$', hide-details, type='number')
                template(v-else)
                    v-textarea(:value='value', label='', dense, hide-details, @input='emitChange', color='brand', outlined)

</template>
<script>
export default {
    props: {
        value: {
            type: [String, Number],
            default () {
                return '';
            }
        },
        text: {
            type: String,
            default () {
                return '';
            }
        },
        lineclamp: {
            type: Number,
            default: () => {
                return 0;
            }
        },
        isCurrency: {
            type: Boolean,
            default: () => {
                return false;
            }
        }
    },
    data () {
        return {
            editMode: false,
            dti
        };
    },
    methods: {
        emitChange (value) {
            if (this.isCurrency) {
                value = Number(value);
            }
            this.$emit('input', value);
            this.$emit('change');
        }
    }
};
</script>

<style lang="scss" scoped>
.displayBox {
    max-width: 200px;
    min-width: 100px;
    :hover {
        cursor: pointer;
    }
}
.lineclamp {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
