<template lang='pug'>
.notesEditor.d-flex
    slot
    v-menu(:close-on-content-click='false', transition='scale-transition', offset-y, v-model='menu', max-width='500', v-if='hasNote')
        template(v-slot:activator='{ on, attrs }')
            v-btn.note(icon, v-on='on', color='red', v-if='hasNote', x-small, title='Click to view/edit note')
                v-icon description
        v-card(light)
            v-card-title Note
            v-card-text
                v-textarea(:value='noteText', label='Note', outlined, color='brand', @change='noteUpdated')
            v-card-actions
                v-spacer
                v-btn.white--text(@click='deleteNote', color='red', :loading='deletingNote') Delete
                v-btn(text, @click='menu = false', color='brand') Close
</template>
<script>


export default {
    props: {
        partId: {
            type: String,
            default () {
                return '';
            }
        },
        property: {
            type: String,
            default () {
                return '';
            }
        }
    },
    data () {
        return {
            accessKey: 'satmmls',
            menu: false,
            deletingNote: false
        };
    },
    computed: {
        note () {
            return this.$store.getters['mmls/note'](this.partId, this.property);
        },
        noteText () {
            return this.note?.note;
        },
        hasNote () {
            return Boolean(this.note);
        }
    },
    methods: {
        async saveNote () {
            this.$emit('saving');
            await this.sendCommand({
                action: 'updateMMLNote',
                parameters: {
                    noteId: this.note.id,
                    noteText: this.noteText
                }
            });
            this.$emit('doneSaving');
        },
        noteUpdated (noteText) {
            this.$store.commit('mmls/updateNote', {
                noteId: this.note.id,
                text: noteText
            });
            this.saveNote();
        },
        async deleteNote () {
            this.$emit('saving');
            this.deletingNote = true;
            this.menu = false;
            await this.$store.dispatch('mmls/deleteNote', this.note.id);
            this.deletingNote = false;
            this.$emit('doneSaving');
        }
    }
};
</script>
<style lang="scss" scoped>
.notesEditor {
    align-items: center;
    min-height: 25px;
    height: 40px;
    width: 100%;
    overflow: hidden;
    justify-content: space-between;
    .note {
        position: relative;
        top: 1px;
        right: 1px;
        align-self: start;
    }
}
</style>
